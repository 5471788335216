/**
 * Created by riven on 14.06.2017.
 */
angular.module('manual')
.config(function ($stateProvider, $urlRouterProvider, $locationProvider, TIPS) {
    $locationProvider.html5Mode(false);
    $locationProvider.hashPrefix('');

    $stateProvider
        .state({
            name: 'introduction',
            url: '/',
            templateUrl: 'introduction.html',
            bottomNavStates: {
                right:{
                    url: '#/communication',
                    label: 'Communication guidelines'
                }

            }
        })
        .state({
            name: 'communication',
            url: '/communication',
            templateUrl: 'communication.html',
            controller: 'TipsCtrl',
            resolve:{tips: () => TIPS.communication},
            bottomNavStates: {
                left:{
                    url: '#/',
                    label: 'Introduction'
                },
                right:{
                    url: '#/webdesign',
                    label: 'Webdesign guidelines'
                }

            }
        })
        .state({
            name: 'webdesign',
            url: '/webdesign',
            templateUrl: 'webdesign.html',
            controller: 'TipsCtrl',
            resolve:{tips: () => TIPS.webdesign},
            bottomNavStates: {
                left:{
                    url: '#/communication',
                    label: 'Communication guidelines'
                },
                right:{
                    url: '#/photoshop',
                    label: 'Photoshop guidelines'
                }

            }
        })
        .state({
            name: 'photoshop',
            url: '/photoshop',
            templateUrl: 'photoshop.html',
            controller: 'TipsCtrl',
            resolve:{tips: () => TIPS.photoshop},
            bottomNavStates: {
                left:{
                    url: '#webdesign',
                    label: 'Webdesign guidelines'
                }
            }
        });

    $urlRouterProvider.otherwise('/');
});