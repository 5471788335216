/**
 * Created by riven on 14.06.2017.
 */
angular.module('manual')
    .controller('TipsCtrl', function($scope, $state, tips, $sce){
        $scope.tips = tips;
        for (let i = 0; i < tips.length; i++) {
            let tip = tips[i];
            if(!tip.contentSecure){
                tip.contentSecure = $sce.trustAsHtml(tip.content);
            }
        }
    });