let myApp = angular.module('manual', ['ui.router', 'manual.templates', "ngAnimate"]);

myApp.run(function ($rootScope, $transitions) {
    $rootScope.sideNav = {
        open: false
    };
    console.log("$rootScope", $rootScope);
    $transitions.onSuccess({to: '**'}, function (transition) {
        let toState = transition.$to();
        $rootScope.bottomNavLinks = toState.bottomNavStates;
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    });
});