/**
 * Created by riven on 14.06.2017.
 */
angular.module('manual')
    .constant('TIPS', {
        communication: [
            {
                title: 'Make sure all current designs are accessible from the same links.',
                content: `
                    If a wireframe updates, it should be accessible from the same link as previous version - this way
                    there is no possibility that we'll start working on old designs.
                `
            },
            {
                title: `Keep us up to date on changes, write change list, especially when something small happens (like font
                    size,
                    font face, color shade etc).`,
                content: `There is very small chance that developer will see all of the changes after design updates as well as
                the designer does. Yes, we can pixel hunt for all the changes and in the end, we will find it, but that's a
                lot of time wasted looking for something that can be described in a few bullets.`
            },
            {
                title: `Keep updates in the same design/wireframe file.`,
                content: `If any of the views changes, or additions like popovers, please add it to previous designs as the
                    separate layer, layer comp and/or artboard. This way we'll still have up to date design of given screen in
                    one place.`
            },
            {
                title: `Don't forget to notify us when something changes.`,
                content: `This is especially required when designs/wireframes are being kept in online collab form (like
                    dropbox, adobe cloud, google drive, google docs etc.). This way we won't miss anything, especially when our
                    focus moved to a different screen. What a great opportunity to write short change list™ too!`
            },
            {
                title: `Whenever you think of animations have them mocked in UX wireframe or in another animation tool.`,
                content: `...or at least provide as many examples as possible from different sites.`
            },
            {
                title: `When in doubt (about animations, behavior, scaling) - add an extra note with more information.`,
                content: ` When not in doubt - add an extra note anyway. There is never such thing as a default concept. 
                Never assume that something is straight forward until it is spelled out. When handing over your 
                PSD just add some design highlights to it - how it is grouped, where there are hidden layers etc. 
                Want to have something happen on hover or on click? Add little mouse cursor with event name under it.`
            }
        ],
        webdesign: [
            {
                title: `Think of RWD as something fluid, not a set of breakpoints.`,
                content: `
                Let's say you follow Bootstrap 3 breakpoints and have <code>320px</code> (x2 because of pixel ratio), <code>768px</code> (x2), <code>992px</code> and <code>1200px</code>  designs. That's great! But developers might have a lot of questions to them anyway: 
<ul>
<li>How will the image behave on the mobile screen with <code>375px</code> width? Will image stretch horizontally? What happens then with vertical size? If it won't stretch then should it stick to left? right?</li>
<li>How will <code>1200px</code> upwards behave?</li>
<li>How should landscape view look like on mobile devices?</li>
</ul> 
Whenever possible attach a note or short description of such behavior. If you have spare time create additional breakpoint designs - this will always help.
                `
            },
            {
                title:`Use different content length for repeating elements. Use way too long examples too!`,
                content: `Many times texts won't fit in a single line. Should we cut it with an ellipsis? Wrap it? Wrapping might break overall alignment. What about translations? Did you know that many languages will need more space to show the same message? What about the languages that will noticeably shorten the message?`
            },
            {
                title:`Avoid using fonts installed with your design tool.`,
                content: `You might be aware of a fact that photoshop gives you bunch of different fonts. This means that most of the nice fonts you’ll use won’t be there for end-users. Look for alternatives on Google Fonts. Bear in minds that not all of them will have a character list for non-latin alphabets which may render them useless for languages like Chinese.`
            },
            {
                title:`Prepare a list of used fonts and try to keep it short.`,
                content: `Every font type and weight will end up being separate font file. If there are more than two font faces, then you’ll see an impact on loading times and esthetics. Go ahead and select your fonts in Google Fonts loader. It will tell you how much of an impact will those fonts have together during page load.`
            },
            {
                title:`Think of creating a universal style guide.`,
                content: `And what's more important - once you do - use it!`
            },
            {
                title:`Avoid creating exceptions to the style guide on different pages.`,
                content: `Whenever you feel like you have to do it think of the reason. Is there new shade of color? Why? Maybe there should be a new color added to the style guide? Or maybe even along with a new rule that will unify your exception? Many times it will be something simple like “It needs to be lighter on dark background” etc.`
            },
            {
                title:`Prepare SVG for each custom shape used in the design.`,
                content: `
                SVG is a great tool for optimizing assets in web design. One shape may be used with different colors and sizes without losing quality. Remember to verify your SVG after exporting it - some of them will have just png/jpg inlined inside.`
            },
            {
                title:`Keep in mind that there are limits to svg scalability. `,
                content: `SVG displayed in <code>16px</code>x<code>16px</code> square will still have only <code>256</code> points on users monitor (except for retina which will be limited to <code>1024</code> points in this case). If you want sharp effect, especially on flat lines, try to align path points to fixed grid (like <code>16px</code>x<code>16px</code> one in this example). Otherwise browser might “blur out” some of the borders.`
            }
        ],
        photoshop:[
            {
                title:`Group everything. No, I mean everything!`,
                content: `
<ul>
<li>Are there few pages? Create separate layer groups!</li>
<li>Are there common elements like headers? Group it!</li>
<li>Is content of a page divided into sections? group, group, group!</li>
<li>Does section have repeated elements? Subgroup!</li>
<li>Maybe there are some elements that have a different state? Group!</li>
<li>Or maybe...? Group!</li>
</ul>
                `
            },
            {
                title:`Are there vector smart objects? Don’t forget to attach a separate svg.`,
                content: `Otherwise programmer will not have those shapes and he’ll have to cut out rasterized version of it. Such a waste of vector format abilities :/
`
            },
            {
                title:`Use layer colors.`,
                content: `This is small, but nice feature which helps color code layer tree. Programmers will be able 
                to find everything much faster once the colors stick to their subconscious.`
            }
        ]
    });