$(function () {
    $(".scroll-to-id").click(function (e) {

        var destination = $(this).attr('data-scroll-id') || $(this).attr('href');
        var destinationElement = $(destination);

        if (!destinationElement.length)
            return;

        e.preventDefault();

        $('html, body').animate({
            scrollTop: destinationElement.offset().top - 10
        }, 500);
    });
});